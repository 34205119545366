import React from "react"
import * as queryString from "query-string"

// INFO: Entrando en https://marktelseleccion.es/r?rid=u4WDgC redirigimos a https://ejob.chat/Marktel-u4WDgC
const RedirectPage = ({location}) => {
  let { i } = queryString.parse(location.search)

  if (typeof window !== "undefined") {
      window.location.href = 'https://ejob.chat/Marktel-' + i;
  }

  return null
}

export default RedirectPage
